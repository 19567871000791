@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Paleta de Colores */
body {
  background: linear-gradient(135deg, #2a2a2a, #141414); /* Fondo degradado más vibrante */
  color: #d1d1d1; /* Texto principal en gris claro */
}

.header {
  background: rgba(0, 0, 0, 0.8);
  animation: fadeInHeader 1.5s ease-in-out;
}

.header a {
  color: #ffffff; /* Blanco para links en el header */
  transition: color 0.3s ease-in-out;
}

.header a:hover {
  color: #00d4ff; /* Azul eléctrico al hacer hover */
}

.text-primaryText {
  color: #ffffff; /* Blanco puro para textos principales */
}

.text-highlight {
  color: hsl(113, 73%, 47%); /* Naranja brillante para titulares y precios */
}

.bg-cardBg {
  background-color: rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.bg-cardBg:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

@keyframes fadeInHeader {
  from { opacity: 0; }
  to { opacity: 1; }
}

.border-t-gradient {
  border-image: linear-gradient(to right, #ff4d4d, #ff1a1a, #cc0000, #990000);
  border-image-slice: 1;
}

.border-t-gradient-header {
  border-image: linear-gradient(to right, #ff4d4d, #ff1a1a, #cc0000, #990000);
  border-image-slice: 1;
  border-width: 4px; /* Ajusta el grosor de la línea según tu preferencia */
}

.category-title {
  font-family: 'Roboto', sans-serif; /* Cambia la fuente a una fuente decorativa de Google Fonts */
  font-size: 2.5rem; /* Tamaño de fuente ajustable */
  font-weight: 700; /* Peso de la fuente */
  color: #fefefe; /* Color del texto */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Sombra del texto para mejorar la visibilidad */
  background: linear-gradient(45deg, #ff6f61, #d43f3f, #ff4e4e); /* Degradado de fondo */
  -webkit-background-clip: text; /* Clip del fondo al texto para degradado */
  -webkit-text-fill-color: transparent; /* Color del texto transparente para mostrar el degradado */
  padding: 0.5rem; /* Espaciado alrededor del texto */
  border-radius: 8px; /* Bordes redondeados opcionales */
  display: inline-block; /* Para que el degradado solo afecte el texto */
}
.animate-fadeIn {
  animation: fadeIn 2s ease-in-out;
}
